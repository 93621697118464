import { z } from "zod";
import type { RiskSummaryViewModel } from "~/src/models/Case/CaseRisk.model";
import type {
  BackendAssignmentEntry,
  BackendCaseEmail,
  BackendCaseNoteEntry,
  BackendCaseStatusEntry,
  BackendDueDateEntry,
} from "~/src/models/Case/History.model";
import {
  deserialiseScreenResult,
  type BackendScreenResultViewModel,
  type BffScreenResultViewModel,
} from "~/src/models/Case/Screen.model";
import { CaseStatus, type MetadataValue } from "~/src/models/Case/Shared.model";
import type { UserInformation } from "~/src/models/User.model";
import type {
  APIPagedResponse,
  HalPage,
  ListRequestModel,
} from "~/src/models/utils/Api.model";
import {
  deserialiseDate,
  type DeserialiseDates,
  type SerialisedDate,
} from "~/src/models/utils/Serialise.model";

export type BackendCaseViewModel = {
  id: string;
  caseId: string;
  createdAt: SerialisedDate;
  lastScreeningDate: SerialisedDate;
  screenResults: BackendScreenResultViewModel[];
  caseDetails: Detail[];
  caseNotes: BackendCaseNoteEntry[];
  caseStatus: BackendCaseStatusEntry;
  statusHistory: BackendCaseStatusEntry[];
  assignments: BackendAssignmentEntry[];
  emails: BackendCaseEmail[];
  flags: CaseFlags;
  dueDates: BackendDueDateEntry[];
  // riskData: number[];
  // risk: number;
};

export type BffCaseViewModel = {
  id: string;
  caseId: string;
  createdAt: SerialisedDate;

  flags: CaseFlags;

  lastScreeningDate: SerialisedDate;
  amountOfHits: number;

  caseDetails: Detail[];
  caseStatus: BackendCaseStatusEntryViewModel | undefined;
  assignedTo: string | null;
  emails: unknown[];
  dueDate: SerialisedDate | null;
} & (
  | {
      latestScreeningId: string;
    }
  | { latestScreening: BffScreenResultViewModel }
);
export type CaseViewModel = DeserialiseDates<BffCaseViewModel>;

export function deserialiseCase(obj: BffCaseViewModel) {
  const ret = obj as any as CaseViewModel;
  if (obj.caseStatus)
    (ret.caseStatus as any).createdAt = deserialiseDate(
      obj.caseStatus.createdAt
    );
  if (obj.dueDate) {
    ret.dueDate = deserialiseDate(obj.dueDate);
  }
  ret.createdAt = deserialiseDate(obj.createdAt);
  ret.lastScreeningDate = deserialiseDate(obj.lastScreeningDate);
  if ("latestScreening" in obj) {
    (ret as any).lastScreening = deserialiseScreenResult(obj.latestScreening);
  }
  return ret;
}

export type BackendCaseStatusEntryViewModel = {
  status: CaseStatus;
  metadata?: MetadataValue[];
  createdBy: string;
  createdAt: SerialisedDate;
};

export type CaseStatusReasonViewModel = {
  value: string;
  displayValue: string;
};

export type Detail = {
  source: string;
  key: string;
  value: string;
};

export type CaseFlags = {
  suspectedUndeclaredDg: boolean;
  suspectedMisdeclaredDg: boolean;
  unusualBehaviour: boolean;
  documentAuthenticity: boolean;
};
export type CaseFlag = keyof CaseFlags;

export type CaseStatusSummaryViewModel = {
  status: CaseStatus;
  reason?: CaseStatusReasonViewModel;
  metadata?: MetadataValue[];
  createdBy: string;
  createdAt: SerialisedDate;
};

export type ScreeningSummaryViewModel = {
  id: string | null;
  amountOfHits: number;
  amountOfHitsDelta: number | null;
  date: SerialisedDate | null;
};

export type BackendCaseSummaryViewModel = {
  id: string;
  // In case the ID and the reference are not the same in future
  caseReference: string;
  riskSummary: RiskSummaryViewModel;
  createdAt: SerialisedDate;
  assignedTo: UserInformation | null;
  flags: CaseFlags;
  lastScreening: ScreeningSummaryViewModel;
  caseStatus: CaseStatusSummaryViewModel | null;
  extraFields: Detail[];
  dueDate: SerialisedDate | null;
};
export type CaseSummaryViewModel =
  DeserialiseDates<BackendCaseSummaryViewModel>;

export function deserialiseCaseSummary(obj: BackendCaseSummaryViewModel) {
  const ret = obj as any as CaseSummaryViewModel;
  if (obj.dueDate) {
    ret.dueDate = deserialiseDate(obj.dueDate);
  }
  ret.createdAt = deserialiseDate(obj.createdAt);
  ret.lastScreening.date = obj.lastScreening.date
    ? deserialiseDate(obj.lastScreening.date)
    : null;
  if (obj.caseStatus?.createdAt && ret.caseStatus?.createdAt) {
    ret.caseStatus.createdAt = deserialiseDate(obj.caseStatus.createdAt);
  }
  return obj as any as CaseSummaryViewModel;
}
export function deserialisePagedCaseSummary(
  res: APIPagedResponse<BackendCaseSummaryViewModel>
) {
  for (let i = 0; i < res.data.length; ++i) {
    (res.data[i] as any) = deserialiseCaseSummary(res.data[i]);
  }
  return res as any as APIPagedResponse<CaseSummaryViewModel>;
}

export type CasePage = HalPage<BackendCaseViewModel, "cases">;

export const assignmentSchema = z.enum(["unassigned", "mine", "all"]);
export type Assignment = "unassigned" | "mine" | "all";

export const caseListOption = z.enum([
  "WithScreenResults",
  "WithScreenResultsHits",
  "WithScreenResultsMatches",
  "WithCaseDetails",
  "WithCaseStatusHistory",
  "WithAssignment",
  "WithDueDates",
]);
export type CaseListOption = z.infer<typeof caseListOption>;

export type CasesListQueryRequest = ListRequestModel & {
  assignment?: Assignment;
  caseListOptions: CaseListOption[];
};

export const STATUS_ICONS: Record<CaseStatus, string> = {
  [CaseStatus.Open]: "mdi-check-bold",
  [CaseStatus.Closed]: "mdi-progress-close",
  [CaseStatus.InProgress]: "mdi-progress-clock",
};

export const EXTRA_CASE_TABLE_COLUMNS = [
  "New",
  "Rescreening",
  "Is DG",
  "Highest Risk",
  "Date Created",
  "Last Screening Date",
] as const;

export type ExtraCaseTableColumn = (typeof EXTRA_CASE_TABLE_COLUMNS)[number];

export const CASES_STATUS_NAMES: Record<CaseStatus, string> = {
  [CaseStatus.Open]: "Open",
  [CaseStatus.InProgress]: "In Progress",
  [CaseStatus.Closed]: "Closed",
} as const;

export const CASES_STATUS_NAMES_LOOKUP: Record<string, string> = {
  0: "Open",
  1: "In Progress",
  2: "Closed",
} as const;

export type Links2 = {
  self: Self2;
  setStatus: SetStatus;
  assign: Assign;
};

export type Self2 = {
  href: string;
  method: string;
};

export type SetStatus = {
  href: string;
  method: string;
};

export type Assign = {
  href: string;
  method: string;
};

export const InspectionState = z.enum([
  "Not Requested",
  "Requested",
  // 'Not Required',
  "In Progress",
  "Completed",
  "Failed",
]);
