export enum CaseStatus {
  Open = 0,
  InProgress = 1,
  Closed = 2,
}

export type Metadata = Record<string, string>;
export type MetadataValue = {
  key: string;
  value: string;
  displayValue: string;
  dropdownSelection?: MetadataValue;
  child?: {
    key: string;
    value: string;
    displayValue: string;
  };
};
