import Prando from "prando";
import { z } from "zod";
import type { BackendScreenResultViewModel } from "~/src/models/Case/Screen.model";

export const getRiskSummaryFromScreenResult = (
  screen: BackendScreenResultViewModel
): RiskSummaryViewModel => {
  // deterministic random number generator, will always return the same number for the same seed
  const generator = new Prando(screen.screeningId + screen.screeningDate);

  const isHardStop = generator.nextBoolean();
  const overallRiskScore = isHardStop ? HARD_STOP : generator.nextInt(0, 100);

  const risksScores = {
    ShipperHistory: isHardStop ? HARD_STOP : generator.nextInt(0, 100),
    InspectionProspect: generator.nextInt(0, 100),
    KeywordExposure: generator.nextInt(0, 100),
    UnusualBehaviour: generator.nextInt(0, 100),
    DocumentAuthenticity: generator.nextInt(0, 100),
  };

  return { overallRiskScore, risksScores };
};
export const HARD_STOP = "HARD_STOP" as const;
export type HardStop = typeof HARD_STOP;
export type RiskValue = number | HardStop;

export type RiskSummaryViewModel = {
  overallRiskScore: RiskValue;
  risksScores: Record<RiskType, RiskValue>;
};

export const RISK_TYPE = z.enum([
  "ShipperHistory",
  "InspectionProspect",
  "KeywordExposure",
  "UnusualBehaviour",
  "DocumentAuthenticity",
]);

export const RISK_TYPE_LABELS: Record<RiskType, string> = {
  ShipperHistory: "Shipper History",
  InspectionProspect: "Inspection Prospect",
  KeywordExposure: "Keyword Exposure",
  UnusualBehaviour: "Unusual Behaviour",
  DocumentAuthenticity: "Document Authenticity",
} as const;

export const RISK_TYPES = Object.keys(RISK_TYPE.Values) as RiskType[];

export type RiskType = z.infer<typeof RISK_TYPE>;
